<template>
  <v-container
    id="login"
    class="fill-height justify-center"
    tag="section"
  >
    <div
      class="background-container"
      :style="`background-image: url(${backgroundUrl})`"
    />
    <div
      class="background-overlay"
    />
    <v-row justify="center">
      <v-slide-y-transition
        appear
      >
        <v-card
          width="600"
        >
          <v-img
            v-if="!isSvg"
            :src="imgUrl"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="400px"
          >
            <v-card-title v-text="$t(cleI18nObjet) + ' ' + nomObjet" />
          </v-img>
          <img
            v-if="isSvg"
            src="@/assets/toucan.svg"
          >

          <v-card-actions>
            <v-spacer />

            <v-btn
              class="success"
              @click="clic()"
            >
              {{ $t(cleI18nBtn) }}

              <v-icon right>
                mdi-send
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
  import restApiService from '@/services/restApiService.js'
  import notificationSonore from '@/services/notificationSonore.js'
  import { mapMutations } from 'vuex'

  export default {
    name: 'ClicPourJoindre',

    data: () => ({
      imgUrl: '',
      backgroundUrl: '',
      nomRoute: '',
      nomObjet: '',
      isSvg: false,
    }),

    computed: {
      cleI18nBtn() {
        return `clicPourJoindre.${this.nomRoute}.btn`
      },

      cleI18nObjet() {
        return `clicPourJoindre.${this.nomRoute}.objet`
      },
    },

    mounted() {
      const nomCourant = this.$route.meta.withMedia
      if (typeof nomCourant !== 'string') {
        this.setInteractionUsager(true)
        return
      }

      this.nomRoute = nomCourant
      this.charger()
    },

    methods: {
      ...mapMutations(['setInteractionUsager']),

      charger() {
        if (this.nomRoute === 'kiosque') {
          this.chargerKiosque()
        } else if (this.nomRoute === 'atelier') {
          this.chargerAtelier()
        } else {
          this.afficherParDefaut()
        }
      },

      clic() {
        notificationSonore.initialiser()
        this.setInteractionUsager(true)
      },

      chargerKiosque() {
        restApiService
          .get('/api/webUser/infoKiosque/' + this.$route.params.idKiosque)
          .then(result => {
            const kiosque = result.data.kiosque
            this.nomObjet = kiosque.nom
            this.imgUrl = restApiService.mediaUrl(kiosque.mediaImage)
            this.backgroundUrl = this.imgUrl
          })
          .catch(error => alert(error))
      },

      chargerAtelier() {
        // Pas d'image lié à un atelier, donc on affiche les images par défaut.
        this.afficherParDefaut()

        restApiService
          .get('/api/ateliers/' + this.$route.params.idAtelier)
          .then(result => {
            const atelier = result.data
            this.nomObjet = atelier.nom
          })
          .catch(error => alert(error))
      },

      afficherParDefaut() {
        this.imgUrl = '@/assets/toucan.svg'
        this.backgroundUrl = '@/assets/login.jpg'
        this.isSvg = true
        console.log(this.imgUrl)
      },
    },

  }
</script>

<style scoped>
.background-container {
  height: 100vh;
  width: 100vw;

  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}
.background-overlay {
  height: 100vh;
  width: 100vw;

  background-color: rgba(0,0,0,0.6);
  position: absolute;
}
</style>

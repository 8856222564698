<template>
  <v-app class="ck-content">
    <v-dialog
      v-if="dialogueInvitationSalonPrivé"
      v-model="dialogueInvitationSalonPrivé"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('kiosque.invitation_salon_prive') }}
        </v-card-title>

        <v-card-text>
          <p>
            {{ msgInvitation.emetteur.prenom }} {{ msgInvitation.emetteur.nom }}
          </p>
          <p>
            {{ $t('kiosque.vous_invite_a_un_salon_prive') }}
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color=""
            text
            @click="dialogueInvitationSalonPrivé = false"
          >
            {{ $t('general.refuser') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="onAccepterInvitation()"
          >
            {{ $t('general.accepter') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogueAcceptationInvitationSalonPrivé"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('kiosque.acceptation_invitation_salon_prive') }}
        </v-card-title>

        <v-card-text>
          {{ $t('kiosque.votre_invitation_a_ete_acceptee') }}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="onAllerSalonPrivé()"
            @click:outside="onAllerSalonPrivé()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogueAtelierFerme"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('kiosque.l_atelier_auquel_vous_tentez_d_acceder_est_actuellement_ferme') }}
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialogueAtelierFerme = false"
            @click:outside="dialogueAtelierFerme = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <clic-pour-joindre v-if="clicPourJoindreAffiche" />

    <dashboard-core-app-bar
      v-if="!clicPourJoindreAffiche"
      v-model="expandOnHover"
    />

    <dashboard-core-drawer
      v-if="!clicPourJoindreAffiche"
      :expand-on-hover.sync="expandOnHover"
    />

    <dashboard-core-view v-if="!clicPourJoindreAffiche" />
  </v-app>
</template>

<script>
  import Salon from '@/services/salon.js'
  import ToucanCom from '@/services/toucanCom.js'
  import horlogeService from '@/services/horlogeService.js'
  import { mapGetters } from 'vuex'
  import ClicPourJoindre from './ClicPourJoindre.vue'

  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreAppBar: () => import('./HautPage'),
      DashboardCoreDrawer: () => import('../MenuDrawer'),
      // DashboardCoreSettings: () => import('../../dashboard/components/core/Settings'),
      DashboardCoreView: () => import('./View'),
      ClicPourJoindre,
    },

    data: () => ({
      expandOnHover: false,
      snackbar: true,
      dialogueInvitationSalonPrivé: false,
      usagerInvitation: {},
      nomSalonInvitation: null,
      dialogueAcceptationInvitationSalonPrivé: false,
      msgInvitation: {},
      msgAcceptation: {},
      dialogueAtelierFerme: false,
    }),

    computed: {
      ...mapGetters(['interactionUsager']),
      clicPourJoindreAffiche() {
        return !this.interactionUsager
      },
    },

    created() {
      ToucanCom.init(this.$root.$store.state.userInfo)
      Salon.ajouterNotificationInvitationSalonPrivé(this.onInvitationSalonPrivé)
      Salon.ajouterNotificationAcceptationInvitationSalonPrivé(
        this.onAcceptationInvitationSalonPrivé,
      )
      ToucanCom.getInstance().enregistrerActionNotification(
        'onRedirectionAtelier',
        this.onRedirectionAtelier,
      )
      ToucanCom.getInstance().enregistrerActionNotification(
        'onFermerAtelier',
        this.onFermerAtelier,
      )
      ToucanCom.getInstance().enregistrerActionNotification(
        'onAfficherMessageAtelierFermer',
        this.onAfficherMessageAtelierFermer,
      )
      horlogeService.synchroniserServeur()
      const intervalSyncServeur = setInterval(() => {
        if (typeof horlogeService.offset !== 'undefined') {
          clearInterval(intervalSyncServeur)
        } else {
          horlogeService.synchroniserServeur()
        }
      }, 10 * 1000)
    },

    beforeDestroy() {
      ToucanCom.getInstance().supprimerEcouteur()
    },

    methods: {
      // pour l'invité
      onInvitationSalonPrivé(msg) {
        console.log('onInvitationSalonPrivé', msg)
        this.msgInvitation = msg
        this.dialogueInvitationSalonPrivé = true
      },

      // pour l'hôte
      onAcceptationInvitationSalonPrivé(msg) {
        console.log('onAcceptationInvitationSalonPrivé', msg)
        this.msgAcceptation = msg
        this.dialogueAcceptationInvitationSalonPrivé = true
      },

      // pour l'invité
      onAccepterInvitation() {
        this.dialogueInvitationSalonPrivé = false

        const usagerHôte = this.msgInvitation.emetteur
        const usagerInvité = this.$store.state.userInfo
        const salon = Salon.initSalonPrivé(usagerHôte, usagerInvité)

        // aviser l'hôte qu'on accepte l'invitation
        Salon.accepterInvitationSalonPrivé(
          usagerInvité,
          usagerHôte,
          this.msgInvitation.salon,
        )

        salon.entrer('Gabarit (accepterInvitationSalonPrivé)').then(() => {
          this.$router.push({
            path: `/orka/sallepri/${this.msgInvitation.salon}`,
          })
        })
      },

      onRedirectionAtelier(msg) {
        this.$router.push({ path: `/orka/salleatelier/${msg.info.id}` })
      },

      onFermerAtelier(msg) {
        this.$router.push({ path: `/orka/vision/${msg.info.id}` })
      },

      onAfficherMessageAtelierFermer(msg) {
        this.dialogueAtelierFerme = true
      },

      // pour l'hôte
      onAllerSalonPrivé() {
        this.dialogueAcceptationInvitationSalonPrivé = false
        const salon = Salon.initSalonPrivé(
          this.$store.state.userInfo,
          this.msgAcceptation.emetteur,
        )
        salon.entrer('Gabarit (onAllerSalonPrivé)').then(() => {
          this.$router.push({
            path: `/orka/sallepri/${this.msgAcceptation.salon}`,
          })
        })
      },
    },
  }
</script>
